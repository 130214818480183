.App {
    min-height: calc(100vh - 16px);
    min-width: calc(100vw - 16px);
    background: #0079bf;
    padding: 8px;
    display: inline-flex;
    flex-direction: column;
  }
  
  .App-header h1 {
    margin: 0;
    color: #f6f6f6;
    font-size: 20px;
    padding: 0 14px 10px;
  }
  
  .App-list {
    display: flex;
  }