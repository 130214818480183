.List {
    min-width: 218px;
  }
  
  .List:not(:first-of-type) {
    margin-left: 8px;
  }
  
  .List-header h2,
  .List-cards h3 {
    margin: 0;
  }
  
  .List-header h2 {
    font-size: 18px;
    padding: 10px 8px 8px;
  }
  
  .List-header,
  .List-cards {
    background-color: #dfe3e6;
    padding: 0 6px 4px;
  }
  
  .List-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .List-cards {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-bottom: 10px;
    max-height: calc(100vh - 102px);
    overflow: auto;
  }
  
  .List-add-button {
    background: none;
    border: none;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 6px;
    display: block;
  }
  
  .List-add-button:hover {
    cursor: pointer;
  }